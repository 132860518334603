<template>
  <div>
    <el-row>
        <el-col style="text-align:left">
            <SearchBar>
              <el-select size="small" v-model="searchParams.kbnType" style="width: 280px;">
                <el-option
                  v-for="item in kbnTypeList"
                  v-bind:key="item.value"
                  v-bind:label="item.label"
                  v-bind:value="item.value">
                </el-option>
              </el-select>
              <el-input size="small" v-model="searchParams.keyword" placeholder="コード/表示名" clearable style="width:280px;margin-left: 15px;"></el-input>
              <el-button size="small" type="primary" style="margin-left: 15px;" icon="el-icon-search" round @click="handleSearch" >{{$t('search.search')}}</el-button>
              <el-button size="small" type="info" style="margin-left: 5px;" round @click="handleReset">{{$t('search.reset')}}</el-button>
            </SearchBar>
        </el-col>
    </el-row>
    <el-row style="margin-top: 13px">
      <el-col :span="6">
        <span class="dash-value dash-num-blue" style="margin-top: 20px">{{total}}</span>&nbsp;
        <span class="dash-title">件</span>&nbsp;
      </el-col>
      <el-col :span="18">
        <div class="searchBar">
          <!-- <el-button size="small" type="success" round icon="el-icon-document-add" @click="handleImport">インポート</el-button> -->
          <el-button size="small" type="primary"  round icon="el-icon-plus" @click="addMaster">新規</el-button>
        </div>
      </el-col>
    </el-row>
    <el-table :data="items" style="width: 100%;padding: 10px 10px 10px 10px;">
      <el-table-column prop="kbnType" label="種別区分"></el-table-column>
      <el-table-column prop="kbnCode" label="コード"></el-table-column>
      <el-table-column prop="kbnName" label="表示名"></el-table-column>
      <el-table-column prop="kbnSeq" label="表示順"></el-table-column>
      <el-table-column prop="kbnCmmt" label="コメント"></el-table-column>
      <el-table-column label="予備">
        <template slot-scope="scope">
          <span v-if="scope.row.kbnType == 'SK008'">原率：{{scope.row.kbnPlm5}}</span> 
          <span v-if="scope.row.kbnType == 'SK011'">変換後：{{scope.row.kbnPlm4}}</span> 
        </template>
      </el-table-column>
      <el-table-column :label="$t('listUser.process')" min-width="240px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" round @click="update(scope.row.id)" plain>修正</el-button>
          <el-button size="mini" type="success" round @click="setRelation(scope.row)" plain 
            v-if="scope.row.kbnType != 'SK008' && scope.row.kbnType != 'SK010' && scope.row.kbnType != 'SK011' && scope.row.kbnType != 'SK012' && scope.row.kbnType != 'SK013'">関連設定</el-button>
          <el-button size="mini" type="danger" round @click="confirmDelete(scope.row.id)" plain v-if="accountInfo.groupId == 1">削除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>

    <!--関連設定テンプ-->
    <el-dialog title="関連設定" :visible.sync="relationForm.isVisible" width="50%">
      <el-form ref="relationForm" :model="relationForm" label-width="150px" :rules="relationRules">
        <el-divider content-position="left">
          <span class="sub-title">関連追加</span>
        </el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="種別区分"  prop="subType" label-width="100px">
              <el-select size="small" v-model="relationForm.subType" @change="handleAddType" style="width: 96%;">
                <el-option
                  v-for="item in selTypeList"
                  v-bind:key="item.value"
                  v-bind:label="item.label"
                  v-bind:value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align:right;">
            <el-button size="small" type="primary" icon="el-icon-plus" round @click="addRelation('relationForm')" style="margin-top: 5px;margin-right: 50px;">追加</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="種別マスタ"  prop="subMaster" label-width="100px">
              <el-select size="small" v-model="relationForm.subMaster" 
              placeholder="キーワードを入力してください。" 
              multiple filterable remote 
              :remote-method="queryMasterList" 
              style="width: 96%;">
                <el-option
                  v-for="item in relationForm.selMasterList"
                  v-bind:key="item.id"
                  v-bind:label="item.kbName"
                  v-bind:value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">
          <span class="sub-title">サブ種別一覧</span>
        </el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="関連した種別区分">
              <el-select size="small" v-model="relationForm.type" @change="handleType" style="width: 95%;">
                <el-option
                  v-for="item in relationForm.selTypeList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-table :data="relationForm.subKbnList" border stripe class="pp-table">
          <el-table-column prop="kbnType" label="種別区分" min-width="80px"></el-table-column>
          <el-table-column prop="kbnCode" label="コード" min-width="80px"></el-table-column>
          <el-table-column prop="kbnName" label="表示名" min-width="150px"></el-table-column>
          <el-table-column prop="kbnSeq" label="表示順" min-width="80px"></el-table-column>
          <el-table-column prop="kbnCmmt" label="コメント" min-width="200px"></el-table-column>
          <el-table-column :label="$t('task.process')" min-width="80px">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" plain round @click="handleDelete(scope.row.id)">削除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button round @click="relationForm.isVisible = false" style="margin-right: 10px;">{{$t('addCustomer.back')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="importModel.show" width="500px">
      <div slot="title">データインポート</div>
      <div class="createDialog">
        <div>
          <el-upload
            ref="upload"
            accept=".xlsm"
            :action="doTextImport"
            :limit="1"
            :file-list="importModel.fileList"
            :on-success="handleImportSuccess"
            :on-change="handleImportChange"
            :auto-upload="false">
            <el-button size="small" type="warning" round style="width:100px;">ファイル選択</el-button>
            <span style="padding-left:20px;">毎回一つxlsmファイルのみインポートできます。</span>
          </el-upload>
        </div>
        <div><label v-show="importModel.errmsg!=''" style="color:red;">{{importModel.errMsg}}</label></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" round @click="importModel.show = false">キャンセル</el-button>
        <el-button size="small" type="primary" round @click.native.prevent="submitImport" style="width:90px;">確 認</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listMaster,deleteMaster,getRelationList,addRelation,deleteRelation,getSelMaster } from "@/api/master";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";

const defaultSearchParams = {
  kbnType: "",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};
export default {
  name: "ListMaster",
  data: () => {
    return {
      kbnTypeList: [
        {value:"", label: "すべて"},
        {value:"SK001", label: "SK001 種別（申込書用）"},
        {value:"SK002", label: "SK002 サービス名（申込書用）"},
        {value:"SK003", label: "SK003 商品プラン名"},
        {value:"SK005", label: "SK005 掲載エリア"},
        {value:"SK006", label: "SK006 リスティング情報①"},
        {value:"SK007", label: "SK007 リスティング情報②"},
        {value:"SK008", label: "SK008 サービスマージン"},
        {value:"SK009", label: "SK009 掲載区分"},
        {value:"SK010", label: "SK010 キャンペーン名"},
        {value:"SK011", label: "SK011 商品プラン変換"},
        {value:"SK012", label: "SK012 サービス用職種"},
        {value:"SK013", label: "SK013 契約用職種"},
      ],
      selTypeList: [
        {value:"SK002", label: "SK002 サービス名（申込書用）"},
        {value:"SK003", label: "SK003 商品プラン名"},
        {value:"SK005", label: "SK005 掲載エリア"},
        {value:"SK006", label: "SK006 リスティング情報①"},
        {value:"SK007", label: "SK007 リスティング情報②"},
        {value:"SK008", label: "SK008 サービスマージン"},
        {value:"SK013", label: "SK013 契約用職種"},
      ],
      searchParams: { ...defaultSearchParams },
      items: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      relationForm: {
        isVisible: false,
        id: null,
        parentType: "",
        subType: null,
        subMaster: null,
        selMasterList: [],
        type: null,
        subKbnList: [],
        selTypeList: [],
      },
      relationRules: {
        subType: [
          { required: true, message: "種別区分を入力してください。", trigger: "blur" }
        ],
        subMaster: [
          { required: true, message: "マスタを入力してください。", trigger: "blur" }
        ],
      },
      doTextImport:process.env.VUE_APP_BASE_API + "/master/import",
      importModel:{
          show:false,
          errMsg:'',
          fileList: []
      },
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      listMaster({
        groupId: this.accountInfo.groupId,
        ...this.searchParams
      }).then(response => {
        this.total = response.data.totalCount;
        this.items = response.data.items;
      });
    },
    handleSizeChange(val){
      this.searchParams = { ...this.searchParams, pageSize: val };
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.searchParams = { ...this.searchParams, pageIndex: val };
      this.refreshData();
    },
    handleSearch() {
        this.searchParams = {...this.searchParams, pageIndex: 1};
        this.refreshData();
    },
    handleReset() {
        this.searchParams = {...defaultSearchParams};
        this.refreshData();
    },
    handleImport() {
      this.importModel.show = true;
      this.importModel.errMsg = "";
      this.importModel.fileList = [];
    },
    handleImportChange(file, fileList) {
      this.importModel.errMsg = "";
      this.importModel.fileList = fileList;
    },
    handleImportSuccess(response, file, fileList) {
      this.importModel.show = false;
      this.refreshData();
    },
    submitImport(param) {
      this.importModel.errMsg = "";
      if (this.importModel.fileList.length <= 0) {
        this.importModel.errMsg = "ファイルを選択してください";
        return;
      }
      this.$confirm("ファイルをインポートしてよろしいでしょうか？", this.$t('common.popup'), {
        confirmButtonText: '確認',
        cancelButtonText: '戻る',
        type: 'warning'
      }).then(() => {
        this.$refs.upload.submit();
      }).catch(() => {
      });
    },
    addMaster(){
      this.$router.push(ROUTER_PATH.ADD_MASTER);
    },
    update(mid){
      this.$router.push({path:ROUTER_PATH.UPDATE_MASTER, query:{id:mid}});
    },
    confirmDelete(mid) {
      this.$confirm("該当マスター情報を削除してよろしいでしょうか？", this.$t('common.popup'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteMaster({
          id: this.accountInfo.id,
          masterId: mid
        }).then(response => {
          this.$message({
            type: 'success',
            message: response.message
          });
          this.refreshData();
        });
      }).catch(() => {
                
      });
    },
    setRelation(entity) {
      if (this.$refs['relationForm'] !== undefined) {
        this.$refs['relationForm'].resetFields();
      }
      this.relationForm.isVisible = true;
      this.relationForm.id = entity.id;
      this.relationForm.parentType = entity.kbnType;
      getRelationList({
        parentId1: entity.id
      }).then(response => {
        this.relationForm.type = response.data.type;
        this.relationForm.subKbnList = response.data.items;
        this.relationForm.selTypeList = response.data.selTypeList;
      });
    },
    handleType(code) {
      getRelationList({
        type: code,
        parentId1: this.relationForm.id
      }).then(response => {
        this.relationForm.type = response.data.type;
        this.relationForm.subKbnList = response.data.items;
        this.relationForm.selTypeList = response.data.selTypeList;
      });
    },
    handleDelete(id) {
      this.$confirm("種別区分を削除してよろしいでしょうか？", this.$t('common.popup'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteRelation({
          id: id,
        }).then(response => {
          this.$message({
            type: 'success',
            message: response.message
          });
          getRelationList({
            type: this.relationForm.type,
            parentId1: this.relationForm.id
          }).then(response => {
            this.relationForm.type = response.data.type;
            this.relationForm.subKbnList = response.data.items;
            this.relationForm.selTypeList = response.data.selTypeList;
          });
        });
      }).catch(() => {
                
      });
    },
    handleAddType(code) {
      this.relationForm.subMaster = null;
      getSelMaster({
        parentId1: this.relationForm.id,
        kbnType: code,
        keyword: null
      }).then(response => {
        this.relationForm.selMasterList = response.data;
      });
    },
    queryMasterList(keyword) {
      getSelMaster({
        parentId1: this.relationForm.id,
        kbnType: this.relationForm.subType,
        keyword: keyword
      }).then(response => {
        this.relationForm.selMasterList = response.data;
      });
    },
    addRelation(formName) {
      this.$refs[formName].validate(valid => {    
        if (valid) {
          this.$confirm("種別区分関連を追加してよろしいでしょうか？", this.$t('common.popup'), {
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }).then(() => {
            addRelation({
              parentId1: this.relationForm.id,
              kbnType: this.relationForm.subType,
              subIdList: this.relationForm.subMaster
            }).then(response => {
              this.$message({
                type: 'success',
                message: response.message
              });
              this.relationForm.subMaster = null;
              getRelationList({
                type: this.relationForm.parentType + "|" + this.relationForm.subType,
                parentId1: this.relationForm.id
              }).then(response => {
                this.relationForm.type = response.data.type;
                this.relationForm.subKbnList = response.data.items;
                this.relationForm.selTypeList = response.data.selTypeList;
              });
            });
          }).catch(() => {
          });
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style>
  .searchBar {
    margin: 0px 0px 10px 0px;
    /*padding: 10px 10px ;*/
    /*background-color: #f7f7f7;*/
    text-align: right;
  }
  .pp-customer .el-form-item {
      margin-bottom: 20px;
  }
  .pp-table {
      width: 96%;
      max-height: 367px;
      margin-left: 20px;
      overflow: auto;
  }
  </style>
